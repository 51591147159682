import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Baskerville, G.L. and Emin, P. 1969. Rapid Estimation of Heat
        Accumulation from Maximum and Minimum Temperatures. Ecology. 50:
        514-517.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Hull, L. A., Myers, C., Ellis, N., and Krawczyk, G. 2003. Management of
        the internal lepidopteran complex in Pennyslvania. Compact Fruit Tree
        36(1):21-25.
      </p>
    </>
  )
}
